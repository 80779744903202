import 'babel-polyfill'
import 'bootstrap'
import 'sticksy'
// Styling
import '../scss/style.scss'

// Fontawesome
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin, faSquareFacebook, faSquareTwitter } from '@fortawesome/free-brands-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import Glide from '../../node_modules/@glidejs/glide/dist/glide.esm'
library.add(faSquareFacebook, faSquareTwitter, faLinkedin, faClock)
dom.watch()
// General
jQuery(document).ready(function ($) {
    if ($('.login.wp-core-ui').length) {
        $('#user_login').attr('placeholder', 'Gebruikersnaam of e-mailadres');
        $('#user_pass').attr('placeholder', 'Wachtwoord')
    }
    let galleryGlide = document.querySelector(".gallery-glide");
    if (galleryGlide) {
        let bullets = galleryGlide.classList.contains("has-bullets");
        let perView = (bullets) ? 3.25 : 4;
        let perViewBreak = (bullets) ? 1.2 : 1.5;
        let focusAt = (bullets) ? 0.625 : 2.5;
        let glide = new Glide(galleryGlide, {
            type: 'carousel',
            startAt: 0,
            perView: perView,
            focusAt: focusAt,
            autoplay: 4000,
            breakpoints: {
                1024: {
                    perView: perViewBreak,
                    focusAt: "center"
                }
            },
            swipeThreshold: false,
            dragThreshold: false,
            hoverpause: false
        })
        glide.mount()
    }
    let landingSlide = document.querySelector(".landing-glide");
    if (landingSlide) {
        let glide = new Glide(landingSlide, {
            type: 'carousel',
            startAt: 0,
            perView: 2.8,
            focusAt: "center",
            autoplay: 4000,
            breakpoints: {
                1024: {
                    perView: 1.2,
                    focusAt: "center"
                }
            },
            swipeThreshold: false,
            dragThreshold: false,
            hoverpause: false
        })
        glide.mount()
    }
    
    let lastCollapsible;
    if ($('.collapsibles').length) {
        lastCollapsible= document.querySelector(".collapsibles__item-header")
        function toggleSlide(container){
            let content= container.querySelector(".collapsibles__item-content");
            if (!container.classList.contains('active')) {
            container.classList.add('active');

            content.style.height = 'auto';
        
            var height = content.clientHeight + 'px';
        
            content.style.height = '0px';
        
            setTimeout(function () {
                content.style.height = height;
            }, 0);
          } else {
            content.style.height = '0px';
        
            // content.addEventListener('transitionend', function () {
                container.classList.remove('active');
            // }, {
            //   once: true
            // });
          }
        };
        $('.collapsibles__item-header').click(function () {
            // $('.collapsibles__item').removeClass('active');
            if(typeof lastCollapsible !== typeof undefined && !lastCollapsible.isEqualNode(this)){
                let container=lastCollapsible.parentElement;
                let content= container.querySelector(".collapsibles__item-content");
                content.style.height = '0px';
        
                // content.addEventListener('transitionend', function () {
                    container.classList.remove('active');
            }
            // $(this).parent().toggleClass('active');
            toggleSlide(this.parentElement)
            
            lastCollapsible= this;
            
        });
    }
    let expandSpecials= document.querySelector(".expand-specials")
    if(expandSpecials){
        let text = document.querySelector('.specials-row-wrapper');
        if(text.clientHeight>1950){
            text.style.height="1950px";
            text.classList.add("hide");
            expandSpecials.classList.remove("d-none");
        }
        
    }
    $('.expand-specials').click(function() {
        $(this).css('opacity', 0);
        let text = $('.specials-row-wrapper');
        text.removeClass("hide")
        let fullHeight = text.css('height', 'auto').height();
        text.css('height', '1950px');
        text.animate({height: fullHeight}, 500)
    })
    let stickyBlog = document.querySelector(".sidebar-content");
    if (stickyBlog) {
        var stickyEl = new Sticksy('.sidebar .sticky', { topSpacing: 100 })
    }
    let lastFilter;
    let currentFilter;
    $('html').click(function (e) {
        if (!lastFilter) {
            // $(document.querySelector(".vacancy__filter-item .active")).removeClass("active");
        }
        if (lastFilter && lastFilter.contains(e.target)) {
            return;
        } else {
            if (currentFilter && !currentFilter.contains(e.target)) {
                $(currentFilter).find(".active").removeClass("active");
            }
            if (lastFilter) {
                $(lastFilter).find(".active").removeClass("active");
            }

        }
    });

    $('.vacancy__filter-item-text').click(function () {
        $(this).prev().toggleClass('active');
        lastFilter = currentFilter;
        currentFilter = this.parentElement;
    });

    $('.vacancy__filter-item input').on('change', function () {
        updateFilter($, $(this).closest('.vacancy__filter-item'));
    });
    $(".apply_filter").click(function () {
        let filter = [];
        let searchParams = new URLSearchParams();
        document.querySelectorAll(".vacancy__filter-item").forEach(x => {
            let checkedItems = [];
            let item = x.querySelector(".vacancy__filter-item-text").dataset.filter
            x.querySelectorAll("input:checked").forEach((input) => {
                checkedItems.push(input.value);
                searchParams.append(input.name, input.value);
            })
            filter[item] = checkedItems;
        })
        window.location.href = (location.pathname + "?" + decodeURI(searchParams.toString()))

    })
    $('.vacancy__filter-item').each(function () {
        updateFilter($, $(this));
    });
    document.querySelectorAll(".file-upload").forEach(upload => {
        let fileField = document.getElementById(upload.dataset.target);
        if (fileField) {
            fileField.addEventListener("change", e => {
                let filename = fileField.files[0].name;
                upload.querySelector(".file-upload-text").innerText = filename;
            })
        }
    })
    let page = 1;
    let btnLoadMore = document.querySelector(".loadmore")
    if (btnLoadMore) {
        btnLoadMore.addEventListener("click", e => {
            page++;
            let row = document.querySelector(".news-row");
            let template = document.getElementById("template");
            let tempString = template.innerHTML;
            let url = "action=fetch_brands&page=" + page
            if (btnLoadMore.dataset.cat != "all") {
                url += "&cat=" + btnLoadMore.dataset.cat;
            }
            fetch("/wp-admin/admin-ajax.php?" + url).then(data => data.json()).then(res => {
                res.forEach(x => {
                    let element = tempString;
                    for (let key in x) {
                        element = element.replace("--" + key.toUpperCase() + "--", x[key]);
                    }
                    row.insertAdjacentHTML("beforeend", element)
                })

            })
        })
    }
    let selectNav = document.getElementById("nav-select")
    if (selectNav) {
        selectNav.addEventListener("change", e => {
            let destination = selectNav.options[selectNav.selectedIndex].dataset.location;
            let elem = document.getElementById(destination);
            elem.scrollIntoView();
        })
    }
    let catChange = document.querySelector(".filter-cat select")
    if (catChange) {
        catChange.addEventListener("change", e => {
            let target = e.target;
            let search = new URLSearchParams(location.search);
            if (target.selectedIndex != 0) {
                search.set("cat", target.options[target.selectedIndex].dataset.value);
            } else {
                search.delete("cat");
            }
            location.replace(location.pathname + "?" + decodeURI(search.toString()))
        })
    }
    document.querySelector(".navbar-toggler").addEventListener("click", e => {
        if (e.currentTarget.ariaExpanded == "true") {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100vh";
        } else {
            document.body.style.overflow = "";
            document.body.style.height = "";
        }
    })
    let BrandTabs = document.querySelectorAll(".single-nieuwsmerk .tab")
    if(BrandTabs){
        let oldIndex=0;
        let oldTabContent=document.querySelector(".tab-content");
        let mobSelect = document.querySelector(".mob-tab-select");
        if(mobSelect){
            mobSelect.addEventListener("change",e=>{
                let curOption= mobSelect.options[mobSelect.selectedIndex];
                if(curOption.dataset.href){
                    window.open(curOption.dataset.href,"_blank");
                    return;
                }
                BrandTabs.forEach(x=>x.classList.remove("active"));
                loadTab(curOption,mobSelect.selectedIndex);
            })
        }
        BrandTabs.forEach((x,i)=>{
            x.addEventListener("click",(e)=>{
                BrandTabs.forEach(x=>x.classList.remove("active"));
                x.classList.add("active");
                $(".tab-content").removeClass("left","right")
                loadTab(x,i)
            })
        })
        function loadTab(item,i){
            let tabContent=document.querySelector(".tab-content[data-id='"+item.dataset.target+"']")
            oldTabContent.classList.add("translate",( oldIndex>i?"left":"right"));
            oldTabContent.classList.remove("active");
            tabContent.classList.add(( oldIndex<i?"left":"right"));
            tabContent.classList.add("translate","active");
            let divs=[tabContent,oldTabContent];
            setTimeout(()=>{
                divs.forEach(x=>x.classList.remove("translate"))
            },501)             
            oldTabContent= tabContent
            oldIndex=i;
        }
    }
    let subTabs = document.querySelectorAll(".sub-tab")
    if(subTabs){
        let oldTabContent=document.querySelector(".sub-tab-content");
        subTabs.forEach((x,i)=>{
            x.addEventListener("click",(e)=>{
                subTabs.forEach(x=>x.classList.remove("active"));
                x.classList.add("active");
                oldTabContent.classList.remove("active")
                let tabContent=document.querySelector(".sub-tab-content[data-id='"+x.dataset.target+"']");
                tabContent.classList.add("active");
                oldTabContent= tabContent;
            })
        })
    }
    const animateCSS = (node, animation, force = false, prefix = 'animate__') => {
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            if (node.classList.contains(`${prefix}animated`) && !force) {
                resolve('Animation ended');
                return;
            }
            const animationName = `${prefix}${animation}`

            node.classList.add(`${prefix}animated`, animationName)

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        })
    }
    document.querySelectorAll(".main .circle").forEach(x => {
        x.addEventListener('click', e => {
            document.querySelectorAll(".kaart-marker").forEach(marker => {
                if(!x.parentElement.isSameNode(marker)) {
                    marker.classList.remove('clicker');
                }
            })
            x.parentElement.classList.toggle('clicker');
        })
        x.addEventListener('mouseenter', e =>{
            x.parentElement.classList.add('active');
        })
        x.addEventListener('mouseleave', e =>{
            x.parentElement.classList.remove('active');
        })
    })
    
    // Inlaad animaties
    var tags = $('.slide')
    $(document).on('scroll', onScrollAnimate)
    function onScrollAnimate() {
        var pageTop = $(document).scrollTop()
        var pageBottom = pageTop + $(window).height()
        for (var i = 0; i < tags.length; i++) {
            var tag = tags[i]
            if ($(tag).offset().top < pageBottom - (window.innerHeight) * 0.10) {
                tag.classList.add('visable')
                tag.ontransitionend = function(e) {
                    e.currentTarget.classList.remove('slide')
                }
            }
            if (tag.dataset.instant) {
                tag.classList.add('visable')
            }
        }
    }

    setTimeout(() => {
        for (let i = 0; i < 10; i++) {
            onScrollAnimate();
        }
    }, 750)
});

function updateFilter($, item) {
    let text = [];
    item.find('input:checked').each(function () {
        text.push($(this).parent().text().trim());
    });
    let $text = item.find('.vacancy__filter-item-text');
    if (text.length > 0) {
        $text.text(text.join(', '));
        $text.addClass('active');
    } else {
        $text.text('Filter op ' + $text.data("filter"));
        $text.removeClass('active');
    }
}


